import '../css/BookAddModal.css'
import axios from 'axios'

function BookAddModal(props) {

    function postBook(data) {

    }  // 서버에 Post 요청을 보내는 코드

    return (
        <div className="BookAddModal" onMouseDown={function () {
            // 배경을 클릭하면 모달창 닫기
            props.setPostModal(false)
        }}>
            <div className="BookAddModal-content" onMouseDown={function (e) {
                // 이벤트 버블링 막기
                e.stopPropagation()
            }}>
                <div className="BookAddModal-text">책 정보를 입력하세요</div>
                <input type="text" id="title" autoComplete="off" placeholder="책 제목"/>
                <input type="text" id="author" autoComplete="off" placeholder="저자"/>
                <input type="text" id="publisher" autoComplete="off" placeholder="출판사"/>
                <input type="text" id="releaseDate" autoComplete="off" placeholder="출시일자"/>
                <input type="text" id="price" autoComplete="off" placeholder="가격"/>
                <div className="BookAddModal-button" onClick={function () {
                    let targetData = {
                        title: document.querySelector('#title').value,
                        author: document.querySelector('#author').value,
                        publisher: document.querySelector('#publisher').value,
                        releaseDate: document.querySelector('#releaseDate').value,
                        price: document.querySelector('#price').value
                    }
                    axios.post("https://glhf.co.kr/api/book", targetData)
                        .then(function (result) {
                            console.log("success: ")
                            console.log(result.data)
                            props.setPostModal(false)
                        })
                        .catch(function (e) {
                            console.error(e.message)
                            alert("다시 시도해주세요. " + e.message)
                        })
                }}>완료</div>
            </div>
        </div>
    )
}

export default BookAddModal;