import '../css/BookModifyModal.css';
import axios from 'axios';

function BookModifyModal(props) {

    return (
        <div className="BookModifyModal" onMouseDown={function () {
            props.setModifyModal(false)
        }}>
            <div className="BookModifyModal-content" onMouseDown={function (e) {
                e.stopPropagation()
            }}>
                <div className="text">수정할 정보를 입력하세요</div>
                <input type="text" autoComplete="off" id="title" defaultValue={props.book.title}/>
                <input type="text" autoComplete="off" id="author" defaultValue={props.book.author}/>
                <input type="text" autoComplete="off" id="publisher" defaultValue={props.book.publisher}/>
                <input type="text" autoComplete="off" id="releaseDate" defaultValue={props.book.releaseDate}/>
                <input type="text" autoComplete="off" id="price" defaultValue={props.book.price}/>
                <div className="BookModifyModal-button" onClick={function () {
                    // put 요청에 보낼 데이터
                    let targetData = {
                        title: document.querySelector('#title').value,
                        author: document.querySelector('#author').value,
                        publisher: document.querySelector('#publisher').value,
                        releaseDate: document.querySelector('#releaseDate').value,
                        price: document.querySelector('#price').value
                    }
                    // put 요청하기
                    axios.put("https://glhf.co.kr/api/book/" + props.book.id, targetData)
                        .then(function () {
                            console.log('ok')
                            props.setModifyModal(false)
                        })
                        .catch(function (e) {
                            console.error(e.message)
                            alert("다시 시도해주세요. " + e.message)
                        })
                }}>완료</div>
            </div>
        </div>
    )
}

export default BookModifyModal;