import '../css/BookCard.css';
import {useState} from 'react';
import axios from 'axios';
import BookModifyModal from '../component/BookModifyModal';

function BookCard(props) {

    const [modifyModal, setModifyModal] = useState(false)

    return (
        <div className="BookCard">
            {/*  title, bookCardBtn  */}
            <div className="row-1">
                {props.book.title}
                {/*  수정, 삭제 버튼 표시  */}
                {
                    props.bookCardBtn ? <div className="BookCard-buttons">
                        <div className="button" onClick={function () {
                            setModifyModal(true)
                        }}>수정</div>
                        <div className="button" onClick={function () {
                            // 삭제할 것인지 묻는 코드
                            let result = window.confirm("데이터가 영구적으로 삭제됩니다.")
                            if(result) {
                                axios.delete("https://glhf.co.kr/api/book/" + props.book.id)
                                    .then(function () {
                                        alert("삭제되었습니다.")
                                    })
                                    .catch(function () {
                                        console.log("삭제 안함")
                                    })
                            } else {
                                alert("cancle")
                            }
                        }}>삭제</div>
                    </div> : null
                }
            </div>
            {/*  author, publisher, releaseDate  */}
            <div className="row-2">
                <span>{props.book.author}</span>
                <span>{props.book.publisher}</span>
                <span>{props.book.releaseDate}</span>
            </div>
            {/*  price  */}
            <div className="row-3">
                가격: {props.book.price} 원
            </div>
            {/*  책 데이터 수정하는 모달창  */}
            <div className="Book-modifyModal">
                {
                    modifyModal ? <BookModifyModal book={props.book} setBooks={props.setBooks} setModifyModal={setModifyModal}></BookModifyModal> : null
                }
            </div>
        </div>
    )
}

export default BookCard;