import '../css/Book.css';
import {useEffect, useState} from 'react';
import axios from 'axios';
import BookAddModal from '../component/BookAddModal';
import BookCard from '../component/BookCard';

function Book() {

    const [books, setBooks] = useState([])  // 서버에서 받아온 데이터를 저장하는 state
    const [postModal, setPostModal] = useState(false)  // 모달창 띄우는 state
    const [bookCardBtn, setBookCardBtn] = useState(false)  // bookCard에 버튼 보여주는 state

    function getBooks() {
        axios.get("https://glhf.co.kr/api/books")
            .then(function (result) {
                setBooks(result.data)
                console.log(result.data)
            })
            .catch(function (e) {
                console.error(e.message)
            })
    }  // 서버에서 데이터를 받아오는 코드: [GET] /api/books

    useEffect(function () {
        getBooks()
    }, [])  // 페이지 첫 로딩시 서버에서 데이터 가져오기

    return (
        <div className="Book">
            {/*  서버에서 받아온 데이터 매핑: [GET] /api/books  */}
            <div className="Book-getList">
                {
                    books.map(function (data) {
                        return (  // BookCard 컴포넌트에 데이터 전송 & 매핑
                            <BookCard book={data} setBook={setBooks} key={data.id} bookCardBtn={bookCardBtn}></BookCard>
                        )
                    })
                }
            </div>
            {/*  상단에 편집/추가 버튼 생성  */}
            <div className="Book-topBtn">
                {/*  편집 버튼 클릭시 BookCard에 수정/삭제 버튼 표시  */}
                <div className="top-button" onClick={function () {
                    //  bookCardBtn: true 이면 버튼 텍스트 완료로 바꿈 false 이면 다시 편집으로
                    if (bookCardBtn) {
                        setBookCardBtn(false)
                        document.querySelector('.top-button:nth-child(1)').innerText = "편집"
                    } else {
                        setBookCardBtn(true)
                        document.querySelector('.top-button:nth-child(1)').innerText = "완료"
                    }
                }}>편집</div>
                {/*  추가 버튼 클릭시 postModal: false => true 로 변경  */}
                <div className="top-button" onClick={function () {
                    setPostModal(true)
                }}>추가</div>
            </div>
            {/*  추가 버튼 클릭시 데이터를 추가하는 모달창 띄우기: [POST] /api/book  */}
            <div className="Book-postModal">
                {/*  postModal: true 이면 모달창 띄우기  */}
                {
                    postModal ? <BookAddModal setPostModal={setPostModal}></BookAddModal> : null
                }
            </div>
        </div>
    )
}

export default Book;