import './css/App.css';
import {useState} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import Book from './pages/Book';

function App() {

    let navigate = useNavigate()
    let [tabs] = useState([
        {
            tabName: "",
            tabLink: "/"
        },
        {
            tabName: "Books",
            tabLink: "/books"
        },
        {
            tabName: "Items",
            tabLink: "/items"
        },
        {
            tabName: "Musics",
            tabLink: "/musics"
        },
        {
            tabName: "Videos",
            tabLink: "/videos"
        }
    ])

    return (
        <div className="App">
            <div className="App-header">
                <div className="title" onClick={function () {
                    navigate(tabs[0].tabLink)
                    document.querySelector('.sub-title').innerText = tabs[0].tabName
                }}>GLHF</div>
                <div className="sub-title"></div>
                <div className="colors">
                    <div className="color" onClick={function () {
                        window.navigator.clipboard.writeText("#264653").then(function () {
                            console.log("ok")
                        })
                    }}></div>
                    <div className="color" onClick={function () {
                        window.navigator.clipboard.writeText("#2A9D8F").then(function () {
                            console.log("ok")
                        })
                    }}></div>
                    <div className="color" onClick={function () {
                        window.navigator.clipboard.writeText("#E9C46A").then(function () {
                            console.log("ok")
                        })
                    }}></div>
                    <div className="color" onClick={function () {
                        window.navigator.clipboard.writeText("#F4A261").then(function () {
                            console.log("ok")
                        })
                    }}></div>
                    <div className="color" onClick={function () {
                        window.navigator.clipboard.writeText("#E76F51").then(function () {
                            console.log("ok")
                        })
                    }}></div>
                </div>
            </div>
            <div className="App-content">
                <div className="App-content-sidebar">
                    {
                        tabs.map(function (data, index) {
                            if (index === 0) {
                                return null;
                            } else {
                                return (
                                    <div className="button" onClick={function () {
                                        navigate(data.tabLink)
                                        document.querySelector('.sub-title').innerText = data.tabName;
                                    }} key={index}>{data.tabName}</div>
                                )
                            }
                        })
                    }
                </div>
                <div className="content scroll">
                    <Routes>
                        <Route path="/" element={<div></div>}></Route>
                        <Route path="/books" element={<Book></Book>}></Route>
                        <Route path="/items" element={<div></div>}></Route>
                        <Route path="/musics" element={<div></div>}></Route>
                        <Route path="/videos" element={<div></div>}></Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default App;
